
.navbar {
	.navbar-brand {
	   img {
	       max-width: 80px;
	       width: 80px;
	   }
	}
	#dropdownuser {
	    text-transform: none; 
	    i {
		   font-size: 2em;
		}
	}
}

