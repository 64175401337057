.dropdown-menu {
    .dropdown-item.active {
       font-weight: bold;
    }   
    
    &.multi-column {
       &.columns-2 {
           min-width: 20rem;
           a {
               float:left;
               display: block;
               width: 50%;
               clear: none;
           }
           a:nth-child(2n+1) {
               clear:left;
           }
       }
       
       &.columns-3 {
           min-width: 22rem;
           
           a {
               float:left;
               display: block;
               width: 33.33%;
               clear: none;
           }
           a:nth-child(3n+1) {
               clear:both;
           }
       }
    }
}