.vex.vex-theme-plain {
    input::placeholder {
        line-height: 200%;
    }
    
    .vex-dialog-button {
        border-radius: .125rem;
        letter-spacing: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        background-color: transparent;
        font-family: Roboto, Helvetica, Arial, sans-serif !important;
        -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
        box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
		-webkit-transition: background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .2s cubic-bezier(.4,0,1,1);
		transition: background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .2s cubic-bezier(.4,0,1,1);
		transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
		transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .2s cubic-bezier(.4,0,1,1);
		will-change: box-shadow,transform;
		color: rgba(0,0,0,.87);
		background-color: transparent;
		border-color: #ccc;      
		display: inline-block;  
		padding: .46875rem 1rem;
	
        &.vex-dialog-button-primary {
	        color: #fff;
	        background-color: #00acdd;
	        border-color: #008cb4;
        }
        
        &.vex-dialog-button-secondary {
        }
    }
}