table.table.dataTable {
    border-collapse: collapse !important;

    th {
        color: black;
    }

    td.options{
        font-size: 10px;
    }

    .contactes {
        display:block;
        line-height: 130%;
        font-size: .875em;

        a {
            color: grey;
        }
    }
    .teatre {
        display:block;
        line-height: 130%;
        font-size: .875em;
    }
}
.dataTables_paginate {
	.page-item.previous a.page-link {
	    padding-left: 10px;
	    padding-right: 10px;
	}

	.page-item.next a.page-link {
	    padding-left: 10px;
	    padding-right: 10px;
	}
}

div.dataTables_wrapper div.dataTables_processing {
	position: fixed;
}

.sumary_wrapper {
	margin: 2rem 0 1rem;
	border-top: 2px solid #ccc;
	border-bottom: 2px solid #ccc;
	padding: 1rem 0;
	text-align: center;
	span {
	   display:block;
	   font-size: 2rem;
	}
}

.search-filter {
    margin-top: 1.5rem;

	label {
	   display: block;
	   max-width: 25%;
	   float: left;
	   margin-right: 5%;
	   padding-top: 20px;
	}
	select {
	   max-width: 20%;
	   margin-right: 5%;
	   float: left;

	   &:last-child{
	       margin-right: 0;
	   }
	}
}

.funcions  {
	span {
		display:block;
		font-size: 1.4rem;
	 }

}