@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(../fonts/Roboto/Roboto-Light.woff) format('woff'), url(../fonts/Roboto/Roboto-Light.ttf) format('truetype'), url(../fonts/Roboto/Roboto-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Roboto/Roboto.eot);
  src: local('Roboto'), local('Roboto-Regular'), url(../fonts/Roboto/Roboto.svg#Roboto) format('svg'), url(../fonts/Roboto/Roboto.woff) format('woff'), url(../fonts/Roboto/Roboto.ttf) format('truetype'), url(../fonts/Roboto/Roboto.eot?#iefix) format('embedded-opentype'), url(../fonts/Roboto/Roboto.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(../fonts/Roboto/Roboto-Medium.woff) format('woff'), url(../fonts/Roboto/Roboto-Medium.ttf) format('truetype'), url(../fonts/Roboto/Roboto-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(../fonts/Roboto/Roboto-Bold.woff) format('woff'), url(../fonts/Roboto/Roboto-Bold.ttf) format('truetype'), url(../fonts/Roboto/Roboto-Bold.woff2) format('woff2');
}

