/*
 * Full calendar (mensual)
 */
.fc {
    margin-bottom: 4rem;
    
    
	.fc-day-grid-event .fc-content {
	    white-space: inherit;
	}
	.fc-view, .fc-view > table {
	    z-index: 0;
	}
	.fc-header-toolbar {
	    
	    .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
	        z-index: 0;
	    }
	    h2 {
	        font-size: 22px;
	        font-weight: 700;
	        text-transform: capitalize;
	    }
	}
}

/*
 * Year calendar (anual)
 */
 
 #year-calendar.calendar{
    overflow:none;
    
    th.year-title:hover {
        cursor: auto;
        background: transparent;
    }
    
	.months-container {
	   .month-container {
	       float:left;
	       width: 25%;
	       margin-bottom: 2rem;
	       
	       &:nth-child(4n+1) {
	           clear:left;
	       }
	   }
	}
}