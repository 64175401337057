.dashboard {
	.cercador {
	   margin-bottom: 2rem;
	   @include media-breakpoint-up(lg) {
                margin-top: 7rem;
            }
	}
	.card-deck {
		&--secondary {
            @include media-breakpoint-up(lg) {
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1030;
				position: fixed;
				padding: 1em;
            }			
		}
	}
}

