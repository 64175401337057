.houdiniForm {
	.form-group {
		label.control-label {
	       font-size: .875em;
	       color: $gray-600;
	    }
	    &.is-focused label.control-label {
            color: $gray-900;
        }
        &.has-danger label.control-label {
            color: $red-500;
        }
	}
	
	.form-control-feedback {
	   font-size: .75em;
	   font-weight: bold;
	   
	   &.error {
	       color: $red-500;
	   }
	}
	
	.panel {
	   margin-bottom: 1rem;
	}
	
	.select2-container {
        background: #fff none repeat scroll 0 0;
        margin-top: 0.55rem;
        padding: 0.15em 0 0;
		.select2-selection {
		   &.select2-selection--single {
		       background-color: transparent;
		       border: none;
		       border-radius: 0;
		       background: no-repeat center bottom,center calc(100% - 1px);
	           background-size: 0 100%,100% 100%;
	           background-image: linear-gradient(to top,#00bcf1 2px,rgba(0,188,241,0) 2px),linear-gradient(to top,rgba(0,0,0,.26) 1px,transparent 1px);
		       line-height: 1;
		       .select2-selection__rendered {
		           padding-left: 0;
		       }
		   }
		   &.select2-selection--multiple {
		      border-radius: 0;
		   }
		   
           .select2-selection__choice {
	          border-radius: 2px;
	       }
		}
		

		
		&--disabled {
			background-color: #e9ecef;
	        opacity: 1;
		} 
	}
	
	
	.cke_ltr {
	   margin-top: 1rem;
	}
	
	textarea {
	   height: 90px;
	}
}

.bmd-form-group input::placeholder {
	opacity: .5;
	font-size: .875em;
}

.select2-dropdown {
	border-radius: 0;
	&.select2-dropdown--above {
	    border-bottom: 1px solid #aaa;
	}
}

/*
 * Forms custom !! 
 */

.custom-file-control, .form-control {
	background-color: #fff;
	margin-top: .5em;
}


/*
 * Datepicker  
 */

.datepicker.dropdown-menu {
	opacity: 1;
	transform: scale(1);
}

/*
 * Multi fields
 */

.multiFields {
    margin-bottom: 2em;
}
.multiFields ul {
    list-style: none;
    display: inline;
    padding: 0;
}
    .multiFields li {
        display: block;
        background: #fff;
        margin-top: 1em;
        margin-bottom: 1em;
        position: relative;
        padding: 1em 1em 0 4em;
        margin-left: 0;
        border: 2px solid #eee;
        min-height: 84px;
    }
    .multiFields li:first-child {
      margin-top: 0;
    }
    .multiFields .collapse-btn {
        font-size: 1em;
        text-align: center;
        display: block;
        margin-bottom: .5em;
    }
    .multiFields .collapse-btn i {
        font-size: 1.5em;
    }
    .multiFields .collapse-btn span.toclose {
        display: none;
    }
    /*.multiFields li div.multiContent {
        overflow: hidden;
        max-height: 120px;
    }
    .multiFields li div.multiContent:hover {
        max-height: none;
    }*/

    .multiFields li label,
    .multiFields li input,
    .multiFields li span.error {
    }

    .multiFields li .options {
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        padding: 15px;
    }
    .multiFields li .delete {
        display: block;
    }
    .multiFields li .move {
        display: block;
        margin-bottom: 1em;
    }
    .multiFields a.add {
        /*margin-left: 20px;*/
    }

    .multiFields .deleteImage {
        display: inline;
        margin-left: 1em;
    }

    
    .edit-link {
        position: absolute;
        right: 0;
        top: .5rem;
        font-size: 12px;
    }
    
    
.select2-dropdown.select2-dropdown--above {
	min-width: 150px;
}    

.dropdown-menu {
	.dropdown-item.btn {
	    line-height: 120%;
	}
}