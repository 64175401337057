/* =========================================================
 * Bootstrap year calendar v1.1.0
 * Repo: https://github.com/Paul-DS/bootstrap-year-calendar
 * =========================================================
 * Created by Paul David-Sivelle
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================= */

/* Main */
.calendar {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: auto;
  direction: ltr;
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.calendar.calendar-rtl {
  direction: rtl;
}
.calendar.calendar-rtl table tr td span {
  float: right;
}

.calendar table {
  margin: auto;
}

.calendar table td,
.calendar table th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: none;
  padding: 4px 5px;
  font-size:12px;
}

/* Header */
.calendar .calendar-header
{
	width:100%;
	margin-bottom:20px;
}

.calendar .calendar-header table
{
	width:100%;
}

.calendar .calendar-header table th
{
	font-size:22px;
	padding:5px 10px;
}

.calendar .calendar-header table th:hover {
  background: #eeeeee;
  cursor: pointer;
}

.calendar .calendar-header table th.disabled,
.calendar .calendar-header table th.disabled:hover {
  background: none;
  cursor: default;
  color:white;
}

.calendar .calendar-header table th.prev,
.calendar .calendar-header table th.next
{
	width:20px;
}

.calendar .year-title {
	font-weight:bold;
	text-align:center;
	height:20px;
	width:auto;
}

.calendar .year-neighbor
{
	color:#aaaaaa;
}

.calendar .year-neighbor2
{
	color:#dddddd;
}

/* Months */
.calendar .months-container {
	width:100%;
	display:none;
}

.calendar .month-container {
	min-width:180px;
	text-align:center;
	height:200px;
	padding:0;
}

.calendar table.month th.month-title
{
	font-size:16px;
	padding-bottom: 5px;
}

.calendar table.month th.day-header
{
	font-size:14px;
}


.calendar table.month tr td,
.calendar table.month tr th
{
	padding:0;
}

.calendar table.month td.week-number {
	cursor: default;
	font-weight:bold;
	border-right:1px solid #eee;
	padding:5px;
}

.calendar .round-left {
	-webkit-border-radius: 8px 0 0 8px;
	-moz-border-radius: 8px 0 0 8px;
	border-radius: 8px 0 0 8px;
}

.calendar .round-right {
	webkit-border-radius: 0 8px 8px 0 ;
	-moz-border-radius: 0 8px 8px 0;
	border-radius: 0 8px 8px 0;
}

.calendar table.month tr td .day-content {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 5px 6px;
}

.table-striped .calendar table.month tr td,
.table-striped .calendar table.month tr th {
  background-color: transparent;
}

.calendar table.month td.day .day-content:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.calendar table.month tr td.old,
.calendar table.month tr td.new,
.calendar table.month tr td.old:hover,
.calendar table.month tr td.new:hover {
  background: none;
  cursor: default;
}
.calendar table.month tr td.disabled,
.calendar table.month tr td.disabled:hover {
  color: #dddddd;
}

.calendar table.month td.day.disabled .day-content:hover {
	background: none;
	cursor: default;
}

.calendar table.month tr td.range .day-content {
   background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.calendar table.month tr td.range.range-start .day-content  {
  border-top-left-radius:4px;
  border-bottom-left-radius:4px;
}

.calendar table.month tr td.range.range-end .day-content  {
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
}

.calendar-context-menu,
.calendar-context-menu  .submenu {
	border: 1px solid #ddd;
	background-color: white;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
	-webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
	position:absolute;
	display:none;
}

.calendar-context-menu .item {
	padding:5px 10px;
	cursor:pointer;
	display:table;
	width:100%;
}

.calendar-context-menu .item:hover {
	background:#eee;
}

.calendar-context-menu .item .content {
	display:table-cell;
}

.calendar-context-menu .item span {
	display:table-cell;
	padding-left:10px;
	text-align:right;
}

.calendar-context-menu .item span:last-child {
	display:none;
}

.calendar-context-menu  .submenu {
	left: 100%;
	margin-top: -6px;
}

.calendar-context-menu  .item:hover > .submenu {
	display:block;
}
