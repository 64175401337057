body.login {
    background: url(./img/logo-fons.svg) 25% 3em no-repeat #F8F8F8;
    background-size: auto auto;
    background-size: 60%;
    
    @include media-breakpoint-up(md) {
      background-size: 30%;
    } 
    
    .card {
        margin-top: 190px;
    }
    
    @include media-breakpoint-up(sm) {
      .card { 
        margin-top: 260px;
      }
    }   
    @include media-breakpoint-up(lg) {
      .card { 
        margin-top: 300px;
      }
    } 
    
      
    .formlogin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    } 
    
	.container-404 {
	    margin-top: 12em;   
	    
	    @include media-breakpoint-up(sm) {
	      margin-top: 15em; 
	    } 
	    
	    @include media-breakpoint-up(lg) {
	      margin-top: 20em; 
	    } 
	}    
}

