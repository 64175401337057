.cercador {
	.card {
		form {
		   margin-bottom: 0;
		   
		   .bmd-form-group.bmd-form-group-lg {
		       padding-top: 0;
		   }
		}
	}
	
	.nav {
	   border-bottom: 1px solid #dee2e6 !important;
	}
	
	.nav-tabs .nav-link.active {
	    border-color: $primary;
	}
}